import axios from "axios";
const Get=async(url)=>{

     const token = localStorage.getItem("token");
let sendResponse;

let config = {
    headers: {
      Authorization: `Bearer ${token}`  // Send the token in the Authorization header
    }
  };

    await axios.get(url,config)
    .then(
        (response)=>{
            // console.log(response.data);
            sendResponse=response;
        },
        (error)=>{
            // console.log(error.message);
            sendResponse=error.message;
        }
    )
        return sendResponse;
}
export default Get;