import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import score_icon from "../Images/score-icon.png";
import menu_icon from "../Images/MD.png";
import demo_img from "../Images/demo-img.png";
import trophy from "../Images/trophy.png";
import Menu from "../Components/Menu";
import { sendScoreApi } from "../Data/data";
import Post from "../Api/Post";
import { Link, useNavigate } from "react-router-dom";
import Get from "../Api/Get";

const Score = () => {
  const navigate = useNavigate();

  //To Load on start
  useEffect(() => {
    if (
      !localStorage.getItem("ani") ||
      localStorage.getItem("ani") === "null" ||
      localStorage.getItem("ani") === undefined
    ) {
      navigate("/login");
    }
    checkColor();
    gettingRequestParams();
    // eslint-disable-next-line
  }, []);

  //Hook to store scores
  const [score, setScore] = useState({});

  //Getting Data from Request Parameter
  const gettingRequestParams = () => {
    // let params=new URLSearchParams(window.location.search);
    // let ani=params.get("ani");

    gettingUserScore();
  };

  //Getting Current Playing User Score from Backend
  const gettingUserScore = async () => {
    let ani = localStorage.getItem("ani");
    let serviceId = localStorage.getItem("serviceId");

    // console.log("ani ",ani);
    //  let request = { "ani": ani, "serviceId": serviceId };

    let promise = await Get(
      `${sendScoreApi}?ani=${ani}&serviceId=${serviceId}&currency=${localStorage.getItem(
        "currency"
      )}`
    );
console.log("repsons",promise.data)
    handleResposne(promise.data);
  };

  //Method to Handle Response
  const handleResposne = (e) => {
    console.log("ksgdfks", e.currentUser.score);
    setScore({
      ...score,
      current: e.currentUser?.score || 0, // fallback to 0 if score is undefined
      top: e.topScorer?.score || 0, // fallback to 0 if top scorer's score is undefined
    });
  };

  //Hook to Store Color
  const [color, setColor] = useState("");
  const [colorTwo, setColorTwo] = useState("");

  //Method to Get Color according to serviceId
  const checkColor = () => {
    let serviceId = localStorage.getItem("serviceId");

    if (serviceId === "11") {
      // setColor("#FFCC00");
      // setColorTwo("black");
      setColor("#5bc2e7");
      setColorTwo("#00263a");
    } else if (serviceId === "1") {
      setColor("#5bc2e7");
      setColorTwo("#00263a");
    } else {
      setColor("#5bc2e7");
      setColorTwo("#00263a");
    }
  };

  return (
    <>
      <Menu
        one="inactive"
        two="active"
        three="inactive"
        four="inactive"
        five="inactive"
        six="inactive"
      />
      <div className="container white-bg">
        <div className="cus-header">
          <div className="col-md-6 col-xs-6">
            <div className="page-icon">
              <img alt="logo" src={score_icon} />
              <span className="text-unselect">Score</span>
            </div>
          </div>
          <div className="col-md-6 col-xs-6">
            <span
              className="cus-menu navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <img alt="logo" src={menu_icon} />
              <span className="text-unselect cursor-pointer">menu</span>
            </span>
          </div>
        </div>
        <div className="container-area">
          <div className="score-area" style={{ background: `${color}` }}>
            <img alt="logo" className="img-user" src={demo_img} />
            <h3 className="text-unselect">Hi Player</h3>
            <p className="text-unselect"> Congratulations on your new score!</p>
            <div className="user-score">
              <div
                className="blue-bg text-unselect"
                style={{ backgroundColor: `${colorTwo}` }}
              >
                User Score:{" "}
                <div className="score text-unselect">{score.current}</div>
              </div>
            </div>
            <p className="text-unselect">
              Beat the best score & become the highest!
            </p>

            <div className="score-row">
              <div className="col-md-5 col-xs-5">
                {" "}
                <img alt="logo" src={trophy} />
              </div>
              <div className="col-md-7 col-xs-7">
                <div
                  className="best-score text-unselect"
                  style={{ backgroundColor: `${colorTwo}` }}
                >
                  Best Score to Beat:
                  <div className="score text-unselect">{score.top}</div>
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/home"
            className="cus-btn-blue container-area-span"
            style={{ color: `${color}`, backgroundColor: `${colorTwo}` }}
          >
            {" "}
            <span className="text-unselect">Continue Playing</span>
          </Link>
          <Footer
            one="inactive"
            two="active"
            three="inactive"
            four="inactive"
          />
        </div>
      </div>
    </>
  );
};
export default Score;
