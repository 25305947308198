import React, { useEffect, useRef, useState } from "react";
import "../Css/login.css";
// import img_logo from "../Images/bigcash-logo.png";
import Select from "react-select";
import { price, subscribeUser } from "../Data/data";
import Get from "../Api/Get";
// import { toast, ToastContainer } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
// import {loginUser} from   '../Data/data';
// import Post from '../Api/Post';
import { useNavigate } from "react-router-dom";
import Post from "../Api/Post";
import classes from "../Css/Subscription.module.css";
import Header from "../Components/Header";
import footer_logo from "../Images/logomain.png";
import Loading from "../Components/Loading";

const Subscription = () => {
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedPack1, setSelectedPack1] = useState(null);
  const [selectedPack2, setSelectedPack2] = useState(null);

  const currencyOptions = [
    { value: "USD", label: "United States Dollar (USD)" },
    { value: "ZWG", label: "Zimbabwe Gold (ZWD)" },
  ];

  const packOptions2 = [
    {
      value: "Daily",
      label: "Daily ZIGO.84",
    },
    {
      value: "Weekly",
      label: "Weekly ZiG5.88",
    },
    {
      value: "Monthly",
      label: "Monthly ZiG10.09",
    },
  ];

  const packOptions1 = [
    {
      value: "Daily",
      label: "Daily USD 0.05",
    },
    {
      value: "Weekly",
      label: "Weekly USD 0.10",
    },
    {
      value: "Monthly",
      label: "Monthly USD 0.40",
    },
  ];

  const handlePackChange1 = (option) => {
    setSelectedPack1(option);
  };

  const handlePackChange2 = (option) => {
    setSelectedPack2(option);
  };

  const handleCurrencyChange = (selectedOption) => {
    if (mobileNumber.trim().length === 0) {
      inputRef.current.focus();
      return;
    }
    setSelectedCurrency(selectedOption);
  };

  const handleMobileChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSubscribe = async (e) => {
    if (
      !selectedCurrency ||
      selectedCurrency.value === "" ||
      selectedCurrency.value === undefined ||
      !mobileNumber ||
      mobileNumber.trim() === "" ||
      mobileNumber.trim() === undefined
    ) {
      // Show toast notification for incomplete input
      toast.error("Please fill all fields");
      return;
    }

    if (selectedCurrency === "USD") {
      if (selectedPack1.value === "" || selectedPack1.value === undefined) {
        toast.error("Please select the package!");
        return;
      }
    }

    if (selectedCurrency === "ZWG") {
      if (selectedPack2.value === "" || selectedPack2.value === undefined) {
        toast.error("Please select the package!");
        return;
      }
    }

    // Log the selected pack
    // console.log("Selected Pack:", selectedOption.value);

    // Log the mobile number
    //console.log("Mobile Number:", mobileNumber);
    localStorage.setItem("ani", mobileNumber);

    hitforSubscriberuser(
      mobileNumber,
      selectedCurrency.value,
      selectedPack1?.value,
      selectedPack2?.value
    );
    // Clear the states after handling the login action
    setMobileNumber(null);
    // setSelectedOption(null);
  };

  const hitforSubscriberuser = (mobileNumber, currency, packUSD, packZIM) => {
    // console.log("MobNo:", mobileNumber);

    let request = {
      ani: mobileNumber,
      currency: currency,
      pack: currency === "USD" ? packUSD : packZIM,
    };
    //console.log("link", subscribeUser);
    setLoading(true);
    let promise = Post(subscribeUser, request);
    promise.then((e) => {
      console.log("e ", e);
      handlingResponse(e);
    });
    // handlingResponse(promise)
  };

  const handlingResponse = (response) => {
    console.log("trs",response);
    // if (e === 1) {
    //   setLoading(false);

    //   toast.error("Billing Pending");
    //   return;
    // } else if (e === 2) {
    //   setTimeout(() => {
    //     setLoading(false);
    //     navigate("/otp");
    //   }, 1000);
    // } else if (e === "Network Error") {
    //   setLoading(false);

    //   //Backend Not Working - so sending to error page
    //   navigate("/error");
    // } else if (e === 0) {
    //   toast.error("Billing Pending");
    //   return;
    // } else if (e === 3) {
    //   setTimeout(() => {
    //     setLoading(false);
    //     navigate("/otp");
    //   }, 1000);
    // } else {
    //   toast.error("Billing Pending");
    //   return;
    // }
    setLoading(false);

  // Extract status code and message from the response
  const { status, data } = response;

  if (status === 200) {
    // Success, OTP sent
    localStorage.setItem("currency",selectedCurrency.value)
    toast.success("otp Verfied"); // Show success message (OTP sent, subscription active, etc.)
    setTimeout(() => {
      navigate("/otp");
    }, 1000);
  } else if (status === 403) {
    // No active subscription found
    toast.error(data); // Show error message that subscription is required
    return;
  } else if (status === 402) {
    // Billing failed
    toast.error(data); // Show billing failure message
    return;
  } else if (status === 404) {
    // User not found
    toast.error(data); // Show user not found message
    return;
  } else if (data === "Network Error") {
    // Backend Not Working - Navigate to error page
    navigate("/error");
  } else {
    // Catch all for unexpected errors
    toast.error("An unexpected error occurred.");
    return;
  }
  };

  const handleReset = () => {
    setMobileNumber(null);
    setSelectedCurrency(null);
    setSelectedPack1(null);
    setSelectedPack2(null);
  };

  return (
    <>
      {/* <div className="screen-1">
    <img className="logo" src={img_logo} alt="Logo" /> */}
      <Header tab="tab2">
        <div className={classes.form_container}>
          {selectedCurrency != null ? (
            <>
              <div className={classes.back_btn} onClick={handleReset}>
                &#8617;
              </div>
              <div className={classes.dropdown_container}>
                <Select
                  className={classes.selector}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#00063f",
                      color: "white",
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: "white", // Change the color of the text inside the box
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: "white", // Change the color of the placeholder text
                    }),
                  }}
                  value={
                    selectedCurrency.value === "USD"
                      ? selectedPack1
                      : selectedPack2
                  }
                  onChange={
                    selectedCurrency.value === "USD"
                      ? handlePackChange1
                      : handlePackChange2
                  }
                  options={
                    selectedCurrency.value === "USD"
                      ? packOptions1
                      : packOptions2
                  }
                  placeholder="Select Package"
                  required
                  defaultMenuIsOpen={true}
                  menuIsOpen={true}
                />
              </div>

              <button
                type="submit"
                style={{ marginTop: "130px" }}
                onClick={handleSubscribe}
                className={classes.btn}
              >
                Subscribe
              </button>
            </>
          ) : (
            <>
              <div className={classes.input_container}>
                <p>+263</p>
                <input
                  ref={inputRef}
                  className={classes.input}
                  type="number"
                  name="number"
                  onChange={handleMobileChange}
                  placeholder="Enter your mobile number"
                />
              </div>
              <div className={classes.dropdown_container}>
                <Select
                  className={classes.selector}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#00063f",
                      color: "white",
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: "white", // Change the color of the text inside the box
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: "white", // Change the color of the placeholder text
                    }),
                  }}
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  options={currencyOptions}
                  placeholder="Select Currency"
                  required
                  defaultMenuIsOpen={true}
                  menuIsOpen={true}
                />
              </div>
            </>
          )}
        </div>

        <div>{loading && <Loading />}</div>

        <div className={classes.footer_container}>
          <img src={footer_logo} alt="econet" className={classes.footer_logo} />
          <p>
            By clicking <b>login</b>, you have read, understood and agreed to be
            bound by the YoGamezPro
            <b> Terms & Conditions</b> for the service.
          </p>
        </div>
      </Header>

      {/* <ToastContainer /> */}
      <Toaster
        position="top-center"
        style={{
          marginTop: "5em", // Adjust the margin to center the notification vertically
        }}
      />
    </>
  );
};

export default Subscription;
